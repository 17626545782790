<template>
    <vue-editor v-model="text" :editor-toolbar="customToolbar"/>
</template>

<script>
import {VueEditor} from "vue2-editor";

export default {
    components: {VueEditor},

    props: {
        initialText: {
            type: String,
            default: ''
        },
    },

    data: () => ({
        text: null,
        customToolbar: [[{
            header: [false, 1, 2, 3, 4, 5, 6]
        }], ["bold", "italic", "underline", "strike"],
            [{
                align: ""
            }, {
                align: "center"
            }, {
                align: "right"
            }, {
                align: "justify"
            }], [{
                list: "ordered"
            }, {
                list: "bullet"
            }, {
                list: "check"
            }], [{
                indent: "-1"
            }, {
                indent: "+1"
            }], // outdent/indent
            [{
                color: []
            }, {
                background: []
            }], // dropdown with defaults from theme
            ["link"], ["clean"] // remove formatting button
        ]
    }),

    beforeMount() {
        this.text = this.initialText;
    },
};
</script>

<style>
    .theme--dark .ql-snow.ql-toolbar {
        background-color: #ffffffdb;
    }
</style>