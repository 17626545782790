import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import App from './App'
import axiosPlugin from './plugins/axios'


Vue.use(vuetify);
Vue.use(VueRouter);
Vue.use(axiosPlugin);


new Vue({
    el: '#app',
    router,
    vuetify,
    store,
    
    components: {
        App
    }
})



