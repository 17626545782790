<template>
    <div class="record-date-info">{{ dateFormatted }} {{ sinceDateString ? '(' + sinceDateString + ')'  : '' }}</div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'RecordDateInfo',
        props: {
            date: String,
        },
        computed: {
            sinceDateString: function() {
                moment.locale('ru');
                const recordDate = moment(this.date);
                const todayStartDate = moment().startOf('day');

                if (recordDate.diff(todayStartDate) === 0) {
                    return '';
                }

                return recordDate.from(todayStartDate);
            },
            dateFormatted() {
                return this.formatDate(this.date);
            }
        },
        methods: {
            formatDate(date) {
                if (!date) {
                    return null;
                }
                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            }
        },
    }
</script>

<style scoped>
    .record-date-info {
        font-size: 11px;
        color: gray;
        margin-bottom: 3px;
    }
</style>