const state = {
    mainPageSnackbar: {
        open: false,
        text: null,
    }
}

const mutations = {
    updateMainPageSnackbar(state, payload) {
        state.mainPageSnackbar.open = payload.open;
        state.mainPageSnackbar.text = payload.text;
    },
}

const actions = {
    setSnackbar({ commit }, payload) {
        commit('updateMainPageSnackbar', payload);
    }
}

const getters = {
    getMainPageSnackbar: state => state.mainPageSnackbar,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}