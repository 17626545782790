import Vue from 'vue'
import Vuex from 'vuex'
import records from './modules/records.js'
import snackbars from './modules/snackbars.js'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		records,
		snackbars,
	}
})

export default store;