<template>
    <router-link class="head-link" to="/tags">
        Управление тегами
    </router-link>
</template>

<script>
    export default {
        name: "LinkToTheManageTagsPage"
    }
</script>

<style scoped>
    .head-link {
        color: white;
    }

    @media (max-width: 940px) {
        .head-link {
            color: rgba(0, 0, 0, 0.87);
        }
    }
</style>