<template>
    <v-card
        class="mx-auto pa-2 mb-4 ql-editor record-item-card"
    >
        <div class="record-item">
            <div class="record-item-text">
                <RecordDateInfo :date="record.date"></RecordDateInfo>
                <div v-html="record.text"></div>
            </div>
            <div class="record-item-additional-block">
                <div>
                    <div v-if="record.tags.length > 0">
                        <v-chip
                            class="mt-2 mr-1"
                            v-for="tag in record.tags"
                            :key="tag.id"
                        >
                            {{ tag.title }}
                        </v-chip>
                    </div>
                </div>
                <div class="record-item-buttons">
                    <!--      @TODO убрать роут в фунции прописать метод роутинга-->
                    <router-link to="/edit-record" class="d-flex">
                        <button
                            title="Редактировать"
                            class="mr-3"
                            @click="goToEditing(record)"
                        >
                            <v-icon>
                                mdi-lead-pencil
                            </v-icon>
                        </button>
                    </router-link>
                    <button
                        title="Удалить"
                        @click="removeRecord(record.id)"
                    >
                        <v-icon>
                            mdi-trash-can-outline
                        </v-icon>
                    </button>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import RecordDateInfo from './RecordDateInfo';
import moment from 'moment';

export default {
    name: "RecordItemComponent",
    components: {
        RecordDateInfo
    },

    data() {
        return {
            currentRecordId: null,
            date: null,
            text: null,
            tags: [],
        }
    },

    props: {
        record: Object,
    },

    methods: {
        goToEditing(el) {
            this.$store.commit('records/updateRecord', {
                date: moment(el.date).toDate(),
                text: el.text,
                tags: el.tags,
                id: el.id,
            });
            //@TODO после коммита делаем переход на страницу редактировня
            this.getRecord();
        },
        getRecord() {
            this.date = this.$store.state.records.currentRecord.date;
            this.text = this.$store.state.records.currentRecord.text;
            this.tags = this.$store.state.records.currentRecord.tags;
        },
        removeRecord(el) {
            this.currentRecordId = el;
            this.$emit('showDialog', this.currentRecordId);
        }
    }
}
</script>

<style scoped>
    .record-item-additional-block {
        display: flex;
        justify-content: space-between;
    }

    .record-item {
        min-height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .record-item-text {
        margin-bottom: 20px;
    }

    .record-item-buttons {
        display: flex;
        position: relative;
        top: 2px;
    }

    .record-item-card {
        min-height: 60px;
        width: 800px;
    }

    .theme--light.v-icon {
        color: #2196F3;
    }

    .theme--light.v-chip {
        background-color: #2196F3;
        border-color: #2196F3;
        color: white;
    }

    @media (max-width: 1200px) {
        .record-item-card {
            width: 600px;
        }
    }

    @media (max-width: 995px) {
        .record-item-card {
            width: 500px;
        }
    }
</style>