<template>
    <div>
        <date-picker
                v-model="localDate"
                value-type="date"
                @input="closeMenuAndEmitDate"
                format="DD.MM.YYYY"
                :append-to-body="false"
        ></date-picker>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
    components: { DatePicker },

    name: "DateComponent",

    props: {
        date: {
            type: Date,
            required: true
        },
    },

    data: () => ({
        localDate: null,
    }),

    methods: {
        closeMenuAndEmitDate() {
            this.$emit('update:date', this.localDate);
        },
    },

    mounted() {
        this.localDate = this.date;
    },
}
</script>

<style>
    .theme--dark .mx-datepicker-body {
        background-color: #272727;
    }

    .theme--dark .mx-calendar-content .cell:hover {
        background-color: #f3f9fe33;
    }
</style>