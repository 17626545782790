<template>
    <div>
        <v-app-bar>
            <v-app-bar-nav-icon class="drawer" @click="drawer = true"></v-app-bar-nav-icon>
            <div class="head-container">
                <router-link to="/">
                    <v-toolbar-title class="d-flex align-center">
                        <img
                                alt="Diary Logo"
                                src="@/img/orig.png"
                                width="90"
                        />
                        <div class="title">
                            My diary
                        </div>
                    </v-toolbar-title>
                </router-link>
                <ul class="head-links">
                    <li>
                        <LinkToTheAddRecordPage></LinkToTheAddRecordPage>
                    </li>
                    <li>
                        <LinkToTheManageTagsPage></LinkToTheManageTagsPage>
                    </li>
                    <li>
                        <DownloadBackupComponent></DownloadBackupComponent>
                    </li>
                </ul>
                <v-switch
                    v-model="isDarkTheme"
                    inset
                    class="head-switch"
                    hide-details
                >
                    <template v-slot:append>
                        <div class="switch-icons">
                            <v-icon v-if="!isDarkTheme" class="light-icon">mdi-white-balance-sunny</v-icon>
                            <v-icon v-if="isDarkTheme" class="dark-icon">mdi-weather-night</v-icon>
                        </div>
                    </template>
                </v-switch>
                <div class="head-exit">
                    <ExitButton></ExitButton>
                </div>
            </div>
        </v-app-bar>
        <v-navigation-drawer
                class="menu"
                v-model="drawer"
                absolute
                temporary
        >
            <v-btn
                    icon
                    color="black"
                    dark
                    class="btn-close"
                    @click="drawer = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-list
                    nav
                    dense
                    class="mt-11"
            >
                <v-list-item-group
                        v-model="group"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-note-plus-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <LinkToTheAddRecordPage></LinkToTheAddRecordPage>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-tag-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <LinkToTheManageTagsPage></LinkToTheManageTagsPage>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-cloud-download-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <DownloadBackupComponent></DownloadBackupComponent>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-location-exit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <ExitButton></ExitButton>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import ExitButton from './ExitButtonComponent';
import DownloadBackupComponent from "@/components/DownloadBackupComponent.vue";
import LinkToTheManageTagsPage from "@/components/pages/LinkToTheManageTagsPage";
import LinkToTheAddRecordPage from "@/components/pages/LinkToTheAddRecordPage";

export default {
    name: "HeadComponent",
    components: {
        LinkToTheAddRecordPage,
        LinkToTheManageTagsPage,
        ExitButton,
        DownloadBackupComponent
    },
    data: () => ({
        drawer: false,
        group: null,
        switch1: true,
        isDarkTheme: false,
    }),

    watch: {
        isDarkTheme(val) {
            this.$vuetify.theme.dark = val;
        },
    },
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

    .theme--light.v-app-bar.v-toolbar.v-sheet {
        background-color: #2196f3;
    }
    .title {
        font-size: 28px !important;
        font-family: 'Lobster', cursive !important;
        margin-left: -9px;
        color: white;
        display: none;
    }
    .head-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    .head-links {
        display: flex;
        align-items: center;
        padding-left: 0 !important;
    }
    .head-switch {
        position: absolute;
        right: 140px;
        margin-top: 4px;
    }
    .head-exit {
        position: absolute;
        right: 26px;
        padding: 10px;
        border-radius: 4px;
    }
    .btn-close {
        float: right;
        margin: 8px;
    }
    li {
        cursor: pointer;
        list-style-type: none;
    }
    .theme--light.v-sheet {
        color: white;
    }
    .theme-switch {
        position: relative;
        padding-left: 50px;
    }
    .switch-icons {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        z-index: -10000;
    }
    .switch-icons .dark-icon {
        left: -14px;
    }
    .switch-icons .light-icon {
        left: 12px;
    }
    .light-icon, .dark-icon {
        font-size: 24px;
        color: #fff;
    }


    @media (min-width: 940px) {
        .drawer {
            display: none;
        }
    }

    @media (max-width: 940px) {
        .head-links, .head-exit {
            display: none;
        }
        .title {
            display: block;
        }
    }
</style>

<style>
    .v-input--switch--inset .v-input--switch__track, .v-input--switch--inset .v-input--selection-controls__input {
        width: 56px;
    }
    .v-application--is-ltr .v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple, .v-application--is-ltr .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
        transform: translate(28px, 0) !important;
    }
    .head-link {
        text-decoration: none;
        padding: 8px 12px;
        border-radius: 4px;
    }
    .head-link:hover {
        text-decoration: none;
        background-color: rgba(226, 226, 238, 0.16);
    }

    @media (max-width: 940px) {
        .head-link {
            padding: 0;
        }
        .head-link:hover {
            background-color: transparent;
        }
    }
</style>