import VueRouter from 'vue-router';
import MainPage from '@/components/pages/MainPage.vue';
import AddRecordPage from '@/components/pages/AddRecordPage';
import EditRecordPage from '@/components/pages/EditRecordPage';
import AuthCallbackComponent from '@/components/AuthCallbackComponent';
import ManageTagsPage from '@/components/pages/ManageTagsPage';


const routes = [
    {
        path: "/",
        name: "MainPage",
        component: MainPage,
    },
    {
        path: "/add-record",
        name: "AddRecordPage",
        component: AddRecordPage,
    },
    {
        path: "/edit-record",
        name: "EditRecordPage",
        component: EditRecordPage,
    },
    {
        path: "/tags",
        name: "ManageTagsPage",
        component: ManageTagsPage,
    },
    {
        path: "/auth-callback",
        name: "AuthCallback",
        component: AuthCallbackComponent,
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

export default router;