<template>
    <div>
        <HeadComponent></HeadComponent>
        <div class="tags-content">
            <LinkToTheMainPage></LinkToTheMainPage>
            <h1 class="mb-4 font-weight-regular">
                Управление тегами
            </h1>
            <ManageTagsList></ManageTagsList>
        </div>
    </div>
</template>

<script>
import HeadComponent from "@/components/HeadComponent";
import LinkToTheMainPage from '@/components/pages/LinkToTheMainPage';
import ManageTagsList from "@/components/ManageTagsList";

export default {
    name: 'ManageTagsPage',

    data: () => ({}),

    components: {
        ManageTagsList,
        HeadComponent,
        LinkToTheMainPage,
    }
}
</script>

<style scoped>
.tags-content {
    padding: 20px;
    margin: 20px;
    max-width: 70%;
}

    @media (max-width: 900px) {
        .tags-content {
            max-width: 100%;
        }
    }
</style>