const state = {
	currentRecord: {
		date: null,
		text: null,
		tags: [],
		id: null,
	},
	loading: false,
	recordsSort: 'record_date',
}

const mutations = {
	updateRecord(state, payload) {
		state.currentRecord.date = payload.date;
		state.currentRecord.text = payload.text;
		state.currentRecord.tags = payload.tags;
		state.currentRecord.id = payload.id;
	},
	changeLoading(state, payload) {
		state.loading = payload;
	},
	changeRecordsSort(state, payload) {
        state.recordsSort = payload;
    },
}

const actions = {}

const getters = {
	getUpdateRecord: state => state.currentRecord,
	getChangeRecordsSort: state => state.recordsSort,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}