import axios from 'axios'

const AxiosPlugin = {
    install(Vue, options) {
        axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        axios.interceptors.request.use(function (config) {
            const token = localStorage.getItem('auth_token');

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        })
        Vue.prototype.$axios = axios;
    }
}

export default AxiosPlugin