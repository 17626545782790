<template>
    <div>
        <button
            @click="logout"
            class="btn"
        >
            <v-icon
                    class="logout"
                    color="white"
            >
                mdi-location-exit
            </v-icon>
            <span class="btn-text">Выход</span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'ExitButton',
    data() {
        return {}
    },
    methods: {
        logout() {
            localStorage.removeItem('auth_token');
            location.href = process.env.VUE_APP_AUTH_LOGOUT
        }
    }
}
</script>

<style scoped>
    .btn {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        border-radius: 4px;
    }
    .btn:hover {
        text-decoration: none;
        background-color: rgba(226, 226, 238, 0.16);
    }
    .logout {
        position: relative;
        top: 1px;
    }
    .btn-text {
        margin-left: 4px;
    }

    @media (max-width: 940px) {
        .btn {
            padding: 0;
        }
        .logout {
            display: none;
        }
        .btn-text {
            margin-left: 0;
        }
    }
</style>