<template>
    <div>
        <HeadComponent></HeadComponent>
        <div class="add-record-content">
            <LinkToTheMainPage></LinkToTheMainPage>
            <h1 class="mb-4 font-weight-regular">Добавить запись</h1>
            <AddRecordForm></AddRecordForm>
        </div>
    </div>
</template>

<script>
import HeadComponent from "@/components/HeadComponent";
import AddRecordForm from '@/components/AddRecordForm';
import LinkToTheMainPage from "@/components/pages/LinkToTheMainPage";

export default {
    name: 'AddRecordPage',

    data: () => ({}),

    components: {
        LinkToTheMainPage,
        HeadComponent,
        AddRecordForm,
    }
}
</script>

<style scoped>
.add-record-content {
    padding: 20px;
    margin: 20px;
    max-width: 70%;
}
    
@media (max-width: 900px) {
    .add-record-content {
        max-width: 100%;
    }
}
</style>
