<template>
    <div>
        <div class="isLoading">
            <v-img
                    v-if="this.$store.state.records.loading === true"
                    alt="Loading"
                    class="shrink"
                    contain
                    src="@/img/loading.svg"
                    width="60"
                    height="60"
            />
        </div>
        <div :class="{tagsList: this.$store.state.records.loading}">
            <ManageTagsItem
                    v-for="(tag) in tags"
                    :key="tag.id"
                    :tag="tag"
                    @showDialog="showDialog"
                    @save="updateTheTag"
            ></ManageTagsItem>
            <dialog-component
                    v-on:close="closedTheModalDialog"
                    :showed="showedDialog"
                    v-on:remove="removeTagsItem"
                    :title="title"
            ></dialog-component>
        </div>
        <div class="text-center">
            <SnackbarInfoComponent
                :text="text"
                :snackbar="snackbar"
            ></SnackbarInfoComponent>
        </div>
    </div>
</template>

<script>
    import ManageTagsItem from "@/components/ManageTagsItem";
    import axios from "axios";
    import DialogComponent from "@/components/DialogComponent";
    import SnackbarInfoComponent from "@/components/SnackbarInfoComponent.vue";
    export default {
        name: "ManageTagsList",

        components: {
            SnackbarInfoComponent,
            ManageTagsItem,
            DialogComponent,
        },

        data: () => ({
            tags: [],
            removingTagId: null,
            title: null,
            showedDialog: false,
            saveTitleTag: null,
            saveIdTag: null,
            text: 'Ваши изменения сохранены!',
            snackbar: false,
        }),

        methods: {
            getTags() {
                this.$store.commit('records/changeLoading', true);

                this.$axios.get('/tags/list')
                    .then(response => {
                        this.tags = response.data.data;
                    }).catch(error => {
                    //@todo
                    //alert('Get events error');
                }).finally(() => {
                    this.$store.commit('records/changeLoading', false);
                });
            },
            removeTagsItem() {
                this.$store.commit('records/changeLoading', true);
                this.showedDialog = false;
                axios
                    .post('/tags/remove', {
                        id: this.removingTagId,
                    })
                    .then(() => {
                        this.tags = this.tags.filter((el) => {
                            return el.id !== this.removingTagId;
                        });
                    })
                    .finally(() => {
                        this.$store.commit('records/changeLoading', false);
                    });
            },
            closedTheModalDialog() {
                this.showedDialog = false;
            },
            showDialog(id, title) {
                this.showedDialog = true;
                this.removingTagId = id;
                this.title = 'Удалить тег "' + title + '"?';
            },
            updateTheTag(id, title) {
                this.saveTitleTag = title;
                this.saveIdTag = id;

                this.$store.commit('records/changeLoading', true);

                axios
                    .post('/tags/update', {
                        id: this.saveIdTag,
                        title: this.saveTitleTag,
                    })
                    .finally(() => {
                        this.$store.commit('records/changeLoading', false);
                        this.snackbar = true;
                    });
                this.snackbar = false;
            }
        },

        beforeMount() {
            this.getTags()
        }
    }
</script>

<style scoped>
.tagsList {
    opacity: 0.2;
}

.isLoading {
    position: fixed;
    top: 40%;
    left: 15%;
    transform: translate(0, -50%);
    z-index: 1000;
}



@media (max-width: 980px) {
    .isLoading {
        left: 40%;
    }
}

@media (max-width: 820px) {
    .isLoading {
        left: 35%;
    }
}

@media (max-width: 710px) {
    .isLoading {
        left: 40%;
    }
}

@media (max-width: 580px) {
    .isLoading {
        left: 50%;
    }
}
</style>