import { render, staticRenderFns } from "./AddRecordPage.vue?vue&type=template&id=217ed341&scoped=true&"
import script from "./AddRecordPage.vue?vue&type=script&lang=js&"
export * from "./AddRecordPage.vue?vue&type=script&lang=js&"
import style0 from "./AddRecordPage.vue?vue&type=style&index=0&id=217ed341&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "217ed341",
  null
  
)

export default component.exports