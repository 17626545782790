<template>
    <div>
        <div class="record-date" v-if="date">
            {{ date }}
        </div>
        <div class="text-center" v-if="records.length == 0" v-show="this.$store.state.records.loading === false">
            Записей по выбранным фильтрам нет.
        </div>
        <div class="record-main">
            <div class="isLoading">
                <v-img
                    v-if="this.$store.state.records.loading === true"
                    alt="Loading"
                    class="shrink"
                    contain
                    src="@/img/loading.svg"
                    width="60"
                    height="60"
                />
            </div>
            <div :class="{recordList: this.$store.state.records.loading}">
                <RecordItemComponent
                    v-for="(record) in records"
                    :key="record.id"
                    :record="record"
                    @showDialog="showDialog"
                >
                </RecordItemComponent>
                <dialog-component
                    v-on:close="closedTheModalDialog"
                    :showed="showedDialog"
                    v-on:remove="removeTheRecord"
                    :title="title"
                ></dialog-component>
            </div>
        </div>
        <v-pagination
            class="mt-3"
            v-if="paginationLastPage > 1"
            v-model="paginationCurrentPage"
            :length="paginationLastPage"
            :total-visible="10"
            @input="onPaginateUpdate"
        ></v-pagination>
    </div>
</template>

<script>
import DialogComponent from "@/components/DialogComponent";
import RecordItemComponent from '@/components/RecordItemComponent';
import {pluck} from './../services/Helpers';
import axios from "axios";
import {mapGetters} from "vuex";

export default {
    components: {
        DialogComponent,
        RecordItemComponent,
    },

    name: "RecordsList",

    props: {
        tags: Array,
        date: String,
    },

    data() {
        return {
            records: [],
            paginationCurrentPage: 1,
            paginationLastPage: null,
            showedDialog: false,
            removingRecordId: null,
            title: 'Удалить запись?',
        }
    },

    methods: {
        removeTheRecord() {
            this.$store.commit('records/changeLoading', true);
            this.showedDialog = false;
            // @TODO если удалили все записи в одной дате, то она должна быть неактивной, сейчас после удаления всех записей она остается кликабельноц
            axios
                .post('/records/delete', {
                    id: this.removingRecordId,
                })
                .then(() => {
                    this.records = this.records.filter((el) => {
                        return el.id !== this.removingRecordId;
                    });
                })
                .finally(() => {
                    this.$store.commit('records/changeLoading', false);
                });
        },
        showDialog(el) {
            this.showedDialog = true;
            this.removingRecordId = el;
        },
        closedTheModalDialog() {
            this.showedDialog = false
        },
        getRecords(page = 1, sort) {
            this.$store.commit('records/changeLoading', true);

            let params = {page};

            if (this.date) {
                params.date = this.date;
            }

            if (this.tags.length) {
                params.tags = pluck(this.tags, 'id');
            }

            if (sort) {
                params.sort = sort;
            }
            this.$axios.get('/records/list', {params})
                .then(response => {
                    this.records = response.data.data;
                    this.paginationLastPage = response.data.meta.last_page;
                }).catch(error => {
                //@todo
                //alert('Get events error');
                }).finally(() => {
                    this.$store.commit('records/changeLoading', false);
            });
        },

        onPaginateUpdate() {
            this.getRecords(this.paginationCurrentPage, this.sort);
        },
    },

    computed: {
        ...mapGetters('records', ['getChangeRecordsSort']),
        sort() {
            return this.getChangeRecordsSort;
        },
    },

    watch: {
        date() {
            this.getRecords(1, this.sort);
        },
        tags() {
            this.getRecords(1, this.sort);
        },
        sort() {
            this.getRecords(1, this.sort);
        }
    }
}
</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>

<style scoped>
.record-date {
    font-size: 20px;
}

.recordList {
    opacity: 0.2;
}

.isLoading {
    position: fixed;
    top: 30%;
    right: 70%;
    transform: translate(0, -50%);
    z-index: 1000;
}

@media (max-width: 980px) {
    .isLoading {
        position: fixed;
        top: 30%;
        right: 47%;
        transform: translate(0, -50%);
        z-index: 1000;
    }
}
</style>