export default class AuthLink {
    generateAuthLink(codeChallenge, authLink, authClientId, callbackUrl) {
        let link = authLink;

        link += '?response_type=code';
        link += '&code_challenge=' + codeChallenge;
        link += '&code_challenge_method=S256';
        link += '&client_id=' + authClientId;
        link += '&redirect_uri=' + callbackUrl;

        return link;
    }
}