<template>
    <v-container>
        <v-main class="calendar-content">
            <v-row>
                <v-date-picker
                    v-model="selectedDate"
                    :picker-date.sync="pickerDate"
                    @click:date="onSelectDate"
                    :allowed-dates="allowedDates"
                    :min="datesContainingRecords[0]"
                    :max="datesContainingRecords[datesContainingRecords.length - 1]"
                    locale="ru-RU"
                ></v-date-picker>
            </v-row>
            <v-row>
                <div>
                    <v-checkbox
                        class="calendar-checkbox"
                        v-model="onlyDateWithChosenDateMode"
                        label="Только даты с выбранными тегами"
                    ></v-checkbox>
                </div>
            </v-row>
        </v-main>
    </v-container>
</template>

<script>

import {pluck} from "@/services/Helpers";

export default {
    name: 'CalendarEvents',

    props: {
        tags: Array,
    },

    data: () => ({
        selectedDate: null,
        pickerDate: null,
        datesContainingRecords: [],
        onlyDateWithChosenDateMode: true,
    }),

    methods: {
        allowedDates(val) {
            return this.datesContainingRecords.includes(val)
        },

        onSelectDate(val) {
            return val
        },

        setDatesContainingRecords() {
            let config = {};

            if (this.onlyDateWithChosenDateMode && this.tags.length) {
                config = {
                    params: {tags: pluck(this.tags, 'id')}
                }
            }

            this.$axios.get('records/get-dates-containing-records', config)
                .then(response => {
                    this.datesContainingRecords = response.data;
                });
        }
    },

    watch: {
        selectedDate(val) {
            this.$emit('datechoose', val)
        },
        tags(val) {
            this.setDatesContainingRecords();
        }
    },

    beforeMount() {
        this.setDatesContainingRecords();
    }
}
</script>

<style>
    .calendar-content {
        padding: 0px !important;
    }

    .v-picker__body {
        background: #2589ed07 !important;
    }

    .calendar-checkbox .v-label{
        font-size: 13px !important;
        margin-left: -5px;
    }

    .calendar-checkbox .v-icon {
        font-size: 20px !important;
    }

    .calendar-checkbox .v-input--selection-controls__ripple {
        width: 28px;
        height: 28px;
        margin: 9px;
    }

    .theme--dark.v-application .accent {
        background-color: #82b1ff !important;
        border-color: #82b1ff !important;
    }
</style>