<template>
    <div>
        <v-select
            v-model="sort"
            :items="options"
            item-text="name"
            item-value="value"
            label="Сортировка"
            dense
        ></v-select>
    </div>
</template>

<script>
export default {
    name: "RecordSortComponent",

    data() {
        return {
            options: [
                { name: "По дате записи", value: "record_date" },
                { name: "По дате создания", value: "creating_date" },
            ],
            sort: { name: "По дате записи", value: "record_date" },
        };
    },

    watch: {
        sort() {
            this.$store.commit('records/changeRecordsSort', this.sort);
        }
    },
}
</script>

<style scoped>

</style>