<template>
    <div class="link">
        <router-link class="link__text" to="/">
            На главную
        </router-link>
    </div>
</template>

<script>
export default {
    name: "LinkToTheMainPage",
}
</script>

<style scoped>
.link {
    margin-bottom: 20px;
}

.link__text {
    text-decoration: none;
    color: gray;
    opacity: 0.6;
}

.link__text:hover {
    color: blue;
}
</style>
