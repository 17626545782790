<template>
    <div class="tags">
        <v-text-field
                class="tag-item"
                :id="'tag-item-' + tag.id"
                :value="tag.title"
                :readonly="!editingTag"
                solo
                ref="tagTitle"
        ></v-text-field>
        <div class="btns">
            <button
                    title="Редактировать"
                    class="mr-3"
                    @click="editTag(tag.id)"
                    v-if="editingTag === false"
            >
                <v-icon>
                    mdi-lead-pencil
                </v-icon>
            </button>
            <button
                    title="Сохранить"
                    class="mr-3"
                    @click="saveTag(tag.id)"
                    v-else
            >
                <v-icon >
                    mdi-content-save
                </v-icon>
            </button>
            <button
                    title="Удалить"
                    @click="removeTag(tag.id)"
            >
                <v-icon>
                    mdi-trash-can-outline
                </v-icon>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ManageTagsItem",

        props: {
            tag: Object,
        },

        data() {
            return {
                tagId: null,
                editingTag: false,
                tagTitle: null,
            }
        },

        methods: {
            editTag(id) {
                this.editingTag = true;
                document.getElementById('tag-item-' + id).focus();
            },
            removeTag(el) {
                this.tagId = el
                this.$emit('showDialog', this.tagId, this.tag.title)
            },
            saveTag(id) {
                this.editingTag = false;
                this.$emit('save', this.tag.id, document.getElementById('tag-item-' + id).value);
            }
        }
    }
</script>

<style scoped>
    .tags {
        max-width: 500px;
    }
    .tag-item {
        margin-bottom: 10px;
        max-width: 500px;
    }
    .btns {
        display: flex;
        justify-content: flex-end;
        margin-top: -76px;
        margin-bottom: 20px;
        margin-right: 10px;
    }
    .theme--light.v-icon {
        color: #2196F3;
        caret-color: #2196F3;
    }
</style>