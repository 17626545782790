<template>
    <div></div>
</template>

<script>
import axios from 'axios';
import router from "./../router";

export default {
    name: 'AuthCallbackComponent',
    data() {
        return {}
    },
    methods: {
        authorizeAndRedirect() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get('code');

            axios
                .post(process.env.VUE_APP_AUTH_TOKEN, {
                    grant_type: 'authorization_code',
                    client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
                    redirect_uri: process.env.VUE_APP_AUTH_CALLBACK,
                    code_verifier: localStorage.getItem('auth_code_verifier'),
                    code
                })
                .then(response => {
                    localStorage.removeItem('auth_code_verifier');
                    localStorage.setItem('auth_token', response.data.access_token);
                    router.push('/');
                })
                .catch(error => {
                    alert('Auth error');
                })
        },
    },
    beforeMount() {
        this.authorizeAndRedirect();
    }
}
</script>

<style>

</style>