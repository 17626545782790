<template>
    <v-dialog
        v-model="dialog"
        max-width="max-content"
    >
        <v-card>
            <v-card-title>
                {{ title }}
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="closeAndRemoveTheItem"
                >
                    ДА
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeTheModalDialog"
                >
                    НЕТ
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogComponent",

    props: {
        showed: Boolean,
        title: String,
    },

    data() {
        return {
            dialog: false,
        }
    },

    methods: {
        closeTheModalDialog() {
            this.dialog = false
            this.$emit('close')
        },
        closeAndRemoveTheItem() {
            this.$emit('remove')
        }
    },

    watch: {
        showed() {
            this.dialog = this.showed
        },
    }
}
</script>

<style scoped>
</style>