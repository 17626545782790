<template>
    <div>
        <HeadComponent></HeadComponent>
        <div class="edit-record-content">
            <LinkToTheMainPage></LinkToTheMainPage>
            <h1 class="mb-4 font-weight-regular">
                Редактирование записей
            </h1>
            <EditRecordForm></EditRecordForm>
        </div>
    </div>
</template>

<script>
import HeadComponent from "@/components/HeadComponent";
import LinkToTheMainPage from '@/components/pages/LinkToTheMainPage';
import EditRecordForm from "@/components/EditRecordForm";

export default {
    name: 'EditRecordPage',

    data: () => ({}),

    components: {
        EditRecordForm,
        HeadComponent,
        LinkToTheMainPage,
    }
}
</script>

<style scoped>
.edit-record-content {
    padding: 20px;
    margin: 20px;
    max-width: 70%;
}

@media (max-width: 900px) {
    .edit-record-content {
        max-width: 100%;
    }
}
</style>

