<template>
    <v-btn
        rounded
        elevation="2"
        large
    >
        Сбросить
    </v-btn>
</template>

<script>
export default {
    name: "ResetButton",
}
</script>

<style>
    .theme--light.v-application .blue {
        background-color: #2196F3;
        border-color: #2196F3;
    }
</style>