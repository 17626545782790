<template>
    <div @click="downloadBackup" class="head-link">
        Скачать резервную копию
    </div>
</template>

<script>

export default {
    name: "DownloadBackupComponent",

    methods: {
        downloadBackup() {
            this.getBackupLink()
        },

        getBackupLink() {
            this.$axios.get('backup/get-link').then(response => {
                window.location = response.data.data.link;
            })
        }
    }
}
</script>