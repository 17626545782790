<template>
    <v-combobox
        class="rounded-b-lg component-tags"
        v-model="tagsModel"
        :items="tags"
        clearable
        chips
        :label="labelTag"
        multiple
        solo
        item-text="title"
        item-value="id"
        :search-input.sync="search"
    >
        <template v-slot:selection="{ item }">
            <v-chip
                class="tags-chip"
                text-color="white"
                close
                @click:close="remove(item)"
            >
                {{ item.title }}
            </v-chip>
        </template>
        <template v-slot:no-data v-if="abilityAddNewTag">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        Тег "<strong>{{ search }}</strong>" не найден. Нажмите <kbd>enter</kbd> чтобы создать такой тег
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-combobox>
</template>

<script>
export default {
    props: {
        abilityAddNewTag: {
            type: Boolean,
            default: false
        },
        selectedTags: {
            type: Array,
            default: () => []
        },
        labelTag: String,
    },

    data() {
        return {
            tags: [],
            tagsModel: [],
            search: null
        }
    },

    methods: {
        remove(item) {
            this.tagsModel.splice(this.tagsModel.indexOf(item), 1)
        },

        getTags() {
            this.$axios.get('tags/list').then(response => {
                this.tags = response.data.data
            })
        },

        saveNewTag(tags) {
            this.tagsModel = tags.map(v => {
                if (typeof v === 'string') {
                    //convert string to object for correct display
                    v = {title: v};
                    this.tags.push(v);

                    //save on server
                    this.$axios.post('/tags/add', {
                        title: v.title
                    }).then(result => {
                        v.id = result.data.id
                    });
                }

                return v;
            })
        },

        setSelectedTags() {
            this.tagsModel = this.selectedTags;
        }
    },

    watch: {
        tagsModel(currVal, prevVal) {
            this.$emit('tagschoose', currVal);

            if (this.abilityAddNewTag && currVal.length > prevVal.length) {
                this.saveNewTag(currVal);
            }
        },
    },

    mounted() {
        this.getTags();
        this.setSelectedTags();
    }
}
</script>

<style scoped>
    .v-input {
        flex: none;
    }

    .tags {
        cursor: pointer;
    }

    .theme--light.v-chip {
        background-color: #2196F3;
        border-color: #2196F3;
    }

    .theme--light.v-icon {
        color: white !important;
    }
</style>

<style>
    .component-tags .theme--light.v-chip__close {
        color: white !important;
    }
</style>