<template>
    <div>
        <Tags
            ref="tagsComponent"
            :ability-add-new-tag="true"
            :labelTag="labelTag"
        ></Tags>
        <EditorComponent ref="editorComponent"></EditorComponent>
        <div class="add-record-form-content mt-6">
            <DateComponent
                @update:menu="updateMenu"
                @update:date="updateDate"
                :date="date"
            ></DateComponent>
            <router-link to="/">
                <v-btn
                    @click="saveTheNewRecord"
                    rounded
                    elevation="2"
                    large
                >
                    Сохранить запись
                </v-btn>
            </router-link>
        </div>
    </div>
</template>

<script>
import EditorComponent from "@/components/EditorComponent";
import Tags from "@/components/TagsComponent";
import DateComponent from '@/components/DateComponent';
import axios from 'axios';

export default {
    name: "AddRecordForm",

    data: () => ({
        menu: false,
        date: new Date(),
        labelTag: 'Добавить тег',
    }),

    components: {
        EditorComponent,
        Tags,
        DateComponent,
    },

    methods: {
        saveTheNewRecord() {
            let tags = this.getTags();
            
            axios
                .post('/records/add', {
                    text: this.$refs.editorComponent.text,
                    date: this.convertDateToString(this.date),
                    tags: tags
                })
                .finally(() => {
                    this.$store.commit('snackbars/updateMainPageSnackbar', {
                        open: true,
                        text: 'Запись успешно добавлена!',
                    });
                });
            this.$store.commit('snackbars/updateMainPageSnackbar', {
                open: false,
                text: null,
            });
        },
        updateMenu(menu) {
            this.menu = menu;
        },
        updateDate(date) {
            this.date = date;
        },
        getTags() {
            let tags = [];

            this.$refs.tagsComponent.tagsModel.forEach(function (el) {
                tags.push(el.id)
            })

            return tags;
        },
        convertDateToString(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        }
    },
}
</script>

<style scoped>
    .add-record-form-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .theme--light.v-btn.v-btn--has-bg {
        background-color: #2196F3;
        border-color: #2196F3;
        color: white;
    }


    @media (max-width: 545px) {
        .add-record-form-content {
            flex-direction: column;
        }
    }
</style>