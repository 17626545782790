<template>
    <div>
        <HeadComponent></HeadComponent>
        <div class="isLoading">
            <v-img
                v-if="this.$store.state.records.loading"
                alt="Loading"
                class="shrink"
                contain
                src="@/img/loading.svg"
                width="60"
                height="60"
            />
        </div>
        <div
            :class="{mainContent: this.$store.state.records.loading}"
            class="main-content"
        >
            <div class="main-block main-block--left">
                <RecordSortComponent
                    class="main-sort"
                ></RecordSortComponent>
                <transition name="fade">
                    <div>
                        <RecordsList :date="selectedDate" :tags="selectedTags"></RecordsList>
                    </div>
                </transition>
            </div>
            <div class="main-block main-block--right">
                <h3 class="main-title">Фильтр по тегам</h3>
                <Tag
                    class="main-tags"
                    v-on:tagschoose="onTagChoose"
                    :labelTag="labelTag"
                ></Tag>
                <h3 class="main-title">Фильтр по датам</h3>
                <div class="main-block-filter">
                    <Calendar
                        class="main-calendar"
                        v-on:datechoose="onDateChoose"
                        :tags="selectedTags"
                        ref="calendar"
                    ></Calendar>
                    <transition name="fade">
                        <ResetButton
                            class="main-btn"
                            v-on:click.native="resetRecords"
                            v-show="selectedDate"
                            v-if="show"
                        ></ResetButton>
                    </transition>
<!--                    Кнопка добавления записи снизу справа-->
<!--                    <div class="main-btn-link">-->
<!--                        <router-link to="/add-record">-->
<!--                            <v-fab-transition>-->
<!--                                <v-btn-->
<!--                                        color="blue"-->
<!--                                        dark-->
<!--                                        fab-->
<!--                                >-->
<!--                                    <v-icon>mdi-plus</v-icon>-->
<!--                                </v-btn>-->
<!--                            </v-fab-transition>-->
<!--                        </router-link>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="main-btn-up">
                <v-btn
                    fab
                    small
                    @click="scrollToTop"
                    v-show="displayingScrollBtn"
                >
                    <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
            </div>
        </transition>
        <div class="text-center">
          <SnackbarInfoComponent
              :text="text"
              :snackbar="snackbar"
          ></SnackbarInfoComponent>
        </div>
    </div>
</template>

<script>

import Tag from '../TagsComponent.vue';
import Calendar from '../CalendarComponent.vue';
import RecordsList from '../RecordsListComponent.vue';
import ResetButton from '../ResetButtonComponent.vue';
import HeadComponent from '../HeadComponent.vue';
import AuthLink from "../../services/AuthLink";
import pkceChallenge from 'pkce-challenge';
import SnackbarInfoComponent from "@/components/SnackbarInfoComponent.vue";
import { mapGetters, mapActions } from 'vuex';
import RecordSortComponent from "@/components/RecordSortComponent.vue";

export default {
    name: 'MainPage',

    data: () => ({
        selectedTags: [],
        selectedDate: null,
        show: false,
        labelTag: 'Выбрать теги',
        displayingScrollBtn: false,
    }),

    components: {
        RecordSortComponent,
        SnackbarInfoComponent,
        Tag,
        Calendar,
        RecordsList,
        ResetButton,
        HeadComponent,
    },

    methods: {
        onTagChoose(tags) {
            this.selectedTags = tags;
        },

        onDateChoose(date) {
            this.selectedDate = date;
            this.show = true;
        },

        resetRecords() {
            this.selectedDate = null;
            this.$refs.calendar.selectedDate = null;
        },

        redirectToAuthIfNecessary() {
            if (process.env.VUE_APP_NEED_AUTH !== 'true') {
                return;
            }

            if (localStorage.getItem("auth_token")) {
                return;
            }

            const challenge = pkceChallenge();
            const authLinkObj = new AuthLink();
            const authLink = authLinkObj.generateAuthLink(
                challenge.code_challenge,
                process.env.VUE_APP_AUTH_LINK,
                process.env.VUE_APP_AUTH_CLIENT_ID,
                process.env.VUE_APP_AUTH_CALLBACK
            );

            localStorage.setItem('auth_code_verifier', challenge.code_verifier);
            location.href = authLink;
        },

        initSnackbar() {
            const mainPageSnackbar = this.$store.getters['snackbars/getMainPageSnackbar'];

            this.snackbar = mainPageSnackbar.open;

            this.text = mainPageSnackbar.text;
        },

        scrollToTop() {
            scroll({
                top: 0,
                behavior: "smooth"
            });
        },

        checkScrollPosition() {
            this.displayingScrollBtn = window.pageYOffset > 10;
        },
    },

    computed: {
        ...mapGetters('snackbars', ['getMainPageSnackbar']),
        snackbar: {
            get() {
                return this.getMainPageSnackbar.open;
            },
            set(updated) {
                return updated;
            }
        },
        text: {
            get() {
                return this.getMainPageSnackbar.text;
            },
            set(updated) {
                return updated;
            }
        },

    },

    beforeMount() {
        this.redirectToAuthIfNecessary();

        this.initSnackbar();
    },

    mounted() {
        this.checkScrollPosition();
        window.addEventListener('scroll', this.checkScrollPosition);
    }
}
</script>

<style scoped>
.main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    margin: 20px;
    gap: 40px;
}

.main-block {
    display: flex;
    flex-direction: column;
    max-width: max-content;
}

.main-sort {
    margin-bottom: 20px;
    max-width: 200px;
}

.main-tags {
    width: 290px;
}

.main-btn {
    align-self: center;
    margin-top: 12px;
}

.main-block--right {
   justify-content: center;
    align-items: end;
}

.main-block-filter{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.main-title {
    margin-bottom: 20px;
}

.main-calendar {
    width: auto;
}

.main-btn-link {
    display: none;
    position: absolute;
    right: 0;
    top: 383px;
}

.isLoading {
    opacity: 0;
    position: fixed;
    top: 30%;
    right: 47%;
    transform: translate(0, -50%);
    z-index: 1000;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .7s ease;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}

.main-btn-up {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 1000;
}

.theme--light.v-btn.v-btn--has-bg {
    background-color: #2196f3;
    border-color: #2196f3;
    color: #fff;
}

@media (max-width: 1440px) {
    .main-block--left {
        width: 600px;
    }

}

@media (max-width: 1210px) {
    .main-block--left  {
        width: 500px;
    }

    .main-block--right {
        width: 350px;
    }
}

@media (max-width: 980px) {
    .main-content {
        flex-direction: column;
        padding: 20px;
        margin: 20px;
        align-items: center;
    }

    .main-block--right {
        width: 500px;
        align-items: center;
    }

    .mainContent {
        opacity: 0.2;
    }

    .isLoading {
        opacity: 1;
    }
}

@media (max-width: 580px) {
    .main-block--left, .main-block--right  {
        width: 350px;
    }
}

@media (max-width: 430px) {
    .main-block--left  {
        width: 320px;
    }
}
@media (max-width: 392px) {
    .main-block--left  {
        width: 290px;
    }
}
</style>
