<template>
    <div>
        <Tags
            :selected-tags="selectedTags"
            ref="tagsComponent"
            :ability-add-new-tag="true"
            :labelTag="labelTag"
        ></Tags>
        <EditorComponent
            :initial-text="content"
            ref="editorComponent"
        ></EditorComponent>
        <div class="edit-record-form-content mt-6">
            <DateComponent
                :date="date"
                @update:date="updateDate"
                ref="dateComponent"
            ></DateComponent>
            <router-link to="/">
                <v-btn
                    @click="editTheRecord"
                    color="blue"
                    dark
                    rounded
                    elevation="2"
                    large
                >
                    Сохранить запись
                </v-btn>
            </router-link>
        </div>
    </div>
</template>

<script>
import EditorComponent from "@/components/EditorComponent";
import Tags from "@/components/TagsComponent";
import DateComponent from "@/components/DateComponent";
import {mapGetters} from "vuex";
import axios from "axios";
import moment from "moment";

export default {
    name: "EditRecordForm",

    components: {
        EditorComponent,
        Tags,
        DateComponent,
    },

    data: () => ({
        recordId: null,
        labelTag: 'Добавить тег',
    }),

    methods: {
        editTheRecord() {
            let tags = this.getTags();
            axios
                .post('/records/change', {
                    text: this.$refs.editorComponent.text,
                    date: moment(this.date).toISOString(true).split('T')[0],
                    tags: tags,
                    id: this.recordId,
                })
                .finally(() => {
                    this.$store.commit('snackbars/updateMainPageSnackbar', {
                      open: true,
                      text: 'Запись успешно изменена!',
                    });
                  });
            this.$store.commit('snackbars/updateMainPageSnackbar', {
                open: false,
                text: null,
            });
        },
        getTags() {
            let tags = [];

            this.$refs.tagsComponent.tagsModel.forEach(function (el) {
                tags.push(el.id)
            });

            return tags;
        },
        updateDate(date) {
            this.$store.commit('records/updateRecord', {
                date: date
            });
        },
    },

    computed: {
        ...mapGetters('records', ['getUpdateRecord']),
        date() {
            return this.getUpdateRecord.date;
        },
        content() {
            return this.getUpdateRecord.text;
        },
        selectedTags() {
            return this.getUpdateRecord.tags;
        },
    },

    beforeMount() {
        // Используем геттер getUpdateRecord для получения объекта currentRecord
        const currentRecord = this.$store.getters['records/getUpdateRecord'];

        // Получаем id из объекта currentRecord
        this.recordId = currentRecord.id;
    },
}
</script>

<style scoped>
    .edit-record-form-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: 545px) {
        .edit-record-form-content {
            flex-direction: column;
        }
    }
</style>