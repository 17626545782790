<template>
    <div>
        <v-snackbar
            v-model="snackbarInfo"
            :timeout="timeout"
            class="snackbar"
            color="#e8f5e9"
            min-width="max-content"
        >
            <div class="snackbar-content">
                <v-icon color="#4caf50">
                    mdi-check-circle
                </v-icon>
                <div class="snackbar-title">
                    {{ text }}
                </div>
            </div>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "SnackbarInfoComponent",

    props: {
        text: String,
        snackbar: Boolean,
    },

    data: () => ({
        timeout: 2000,
        snackbarInfo: null,
    }),

    watch: {
        snackbar() {
            this.snackbarInfo = this.snackbar;
        },
    },
}
</script>


<style scoped>
    .snackbar-content {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .snackbar-title {
        color: #4caf50;
    }
</style>